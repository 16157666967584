import { Component } from '@angular/core';
import { BaseComponent } from './base/base.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent extends BaseComponent {

  public SECTION_PLAY: string = 'play';
  public SECTION_RHYTHM_DECK: string = 'rhythm_deck';

  // public currentSection: string;

  constructor() {
    super();
    // this.reset();
  }

  // public reset(): void {
  //   this.SECTION_PLAY = 'play';
  //   this.SECTION_RHYTHM_DECK = 'rhythm_deck';
  //   this.currentSection = null;
  // }

  // public startButtons(section): void {
  //   this.currentSection = section;
  // }

  // public navigateBack(): void {
  //   this.currentSection = null;
  // }

}
