export class AppConstants {

    public static LOCAL_STORAGE_ID: string = 'skill-stat-7238168172-u';

    public static SKIP_LOCATION_CHANGE: boolean = true;

    public static MAIN_PLAY: string = 'play';
    public static MAIN_RHYTHM_DECK: string = 'rhythms';
    public static MAIN_EXTRAS_DECK: string = 'extras';
    public static MAIN_STARTER_KIT: string = 'starter-kit';

}