import { AppConstants } from '../app-constants';
import { RhythmDetails } from '../pages/rhythm/rhythm-details/rhythm-details.class';

export class BaseComponent {

    public TEST_MODE: boolean = false;

    public SKIP_LOCATION_CHANGE: boolean = AppConstants.SKIP_LOCATION_CHANGE;

    public rhythmsMap: Map<number, RhythmDetails> = new Map<number, RhythmDetails>();

    constructor() {
    }

}
