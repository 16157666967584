import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: './pages/home/home.module#HomeModule'
      },
      {
        path: 'play',
        loadChildren: './pages/play/play.module#PlayModule'
      },
      { 
        path: 'play-questions', 
        loadChildren: './pages/play/questions/questions.module#PlayQuestionsModule' 
      },
      {
        path: 'rhythms',
        loadChildren: './pages/rhythm/rhythm.module#RhythmModule'
      },
      {
        path: 'extras',
        loadChildren: './pages/rhythm/rhythm.module#RhythmModule',
        data: {
          isExtras: true
        }
      },
    ]
  },
  { path: 'starter-kit', loadChildren: () => import('./pages/starter-kit/starter-kit.module').then(m => m.StarterKitModule) },
  { path: 'certifications', loadChildren: () => import('./pages/certifications/certifications.module').then(m => m.CertificationsModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
